
@tailwind base;
@tailwind components;
@tailwind utilities;

.r2_img_std {
    height: 4.25rem !important;
}

.r2_img_big {
    height: 8.25rem !important;
}