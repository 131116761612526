
.label {
    @apply flex items-center;
}

.label.label-outline {
    @apply p-5 border border-gray-600 rounded-md focus-within:outline-primary focus-within:outline focus-within:outline-1 focus-within:border-primary ring-primary;
}

.section-header {
    @apply text-lg text-primary font-bold;
}

.section-label {
    @apply font-bold text-lg;
}

.form-control {
    @apply mb-6;
}

.label-text {
    @apply font-bold text-black;
}

.input {
    @apply block px-4 py-6;
}

.radio {
    @apply w-8 h-8 text-primary;
}

.radio + .label-text {
    @apply ml-4 font-normal;
}

.radio:focus {
    @apply ring-primary;
}

.input.input-bordered {
    @apply border border-gray-600 rounded-md ring-primary focus:border-primary;
}

.input.input-bordered[type="phone"] {
    @apply outline-primary;
}

.input.input-file {
    @apply py-4 block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-primary file:text-white hover:file:bg-primary  ring-primary focus:border-primary outline-primary;
}

.checkbox {
    @apply w-4 h-4 text-primary focus:ring-primary;
}

.checkbox + .label-text {
    @apply ml-4 font-normal;
}